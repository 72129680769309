import { useState } from 'react';
import "./videosection.css";

export default function VideoSection() {
    const [isPaused, setIsPaused] = useState(true);

    const playVideo = () => {
        const video = document.querySelector('.logo-video');
        if (video.paused) {
            video.play();
            setIsPaused(false);
        } else {
            video.pause();
            setIsPaused(true);
        }
    }

    return (
        <div className="video-section">
            <video className='logo-video' onClick={playVideo} onPause={() => setIsPaused(true)} onPlay={() => setIsPaused(false)} controls={false}>
                <source src="https://www.streamthegame.it/media/videos/SW_Trailer.mov" type="video/mp4" />
            </video>
            {isPaused && (
                <button className="play-button" onClick={playVideo} aria-label="Play Video">
                    <svg width="100" height="100" viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 5v14l11-7L8 5z" />
                    </svg>
                </button>
            )}
        </div>
    );
}
