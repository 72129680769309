import { useEffect } from 'react';
import './App.css';
import first from "./media/HighresScreenshot00012.webp"
import second from "./media/_MG_8302.webp"
import third from "./media/PresentazioneInflut.webp"
import videohero from "./media/herovideo.mp4"
import logo from "./media/Font+Titolo_Bianco.png"
import softicons from "./media/softicons.png"
import bg from "./media/Sfondo1.png"
import overlay from "./media/overlay.png"
import VideoSection from './videoSection';
import PdfSection from './pdfSection';
import SliderSection from './slidersection';
import AboutUs from './aboutsection';
import Footer from './footer';
import {Helmet, HelmetProvider} from "react-helmet-async";

function App() {
  useEffect(() => {
    const video = document.querySelector('.hero-video');
    video.setAttribute('muted', '');
    video.setAttribute('playsinline', '');
    video.muted = true;
    var playingvideo = video.play();
    if (playingvideo !== undefined) {
      playingvideo.then(function () {

      }).catch(function (error) {
        // Likely low power mode on iOS, show controls

      });
    }
  }, [])
  return (
      <HelmetProvider>
      <>
        <Helmet>
          <title>Stream The Game</title>
          <meta name="description" content="Sito Ufficiale Stream The Game" />
          <meta name="keywords" content="Stream The Game Sara Piergigli Unreal Engine " />
          <meta property="og:title" content="Sito Ufficiale Stream The Game" />
        </Helmet>

        <div className='wrapper' >
          <div className='hero-wrapper'>
            <div className='header'>
          <span className='logo-container'>
            <img src={logo} alt='logo' />
          </span>
              <div className='menu'>
                <a href='/' className='homepage'>HOME</a>
                <a href='#'>PROJECT</a>
                <a href='#'>DOCUMENT</a>
                <a href='#'>ABOUT</a>
              </div>

            </div>
            <video className='hero-video' loop>
              <source src={videohero} type="video/mp4" />
            </video>
            <div className='softicons-wrapper'>
              <img src={softicons} alt='icons' />
            </div>
            <div className='heading-wrapper'>
              <h1>STREAM THE GAME</h1>
              <p>PROGETTO DI TESI</p>
            </div>
            <div className='overlap'>

              <img src={overlay} alt='overlay' />
            </div>
          </div>
          <div className='info-section'>
            <div className='content'>
              <h1>Trasposizione della narrativa a fumetti nel mondo interattivo digitale</h1>
              <p>Sono Sara e questa è la mia tesi di diploma accademico di primo livello in Arte del Fumetto e dell'Illustrazione, nella materia di Tecniche di
                <br /> Modellazione Digitale 3D.<br />
                L’elaborato esplora il processo di
                sviluppo creativo attorno alla trama narrativa da me creata che quindi diventa il filo conduttore del progetto. Questo racconto si sviluppa in tre capitoli: dal primo nasce il
                fumetto e dal secondo la demo del videogioco.</p>
              <span></span>
            </div>
            <div className='content-images'>
              <span className='image-container-content img-frst'><img src={first} alt='img' /></span>
              <span className='image-container-content img-scnd'><img src={second} alt='img' /></span>
              <span className='image-container-content img-thrd'><img src={third} alt='img' /></span>

            </div>
            <span className='background-absolute'>
          <img src={bg} alt="background" />
        </span>
          </div>
          <VideoSection />
          <PdfSection />
          <SliderSection />
          <AboutUs />
          <Footer />
        </div>
      </>
      </HelmetProvider>
  );
}

export default App;
