import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import { EffectCoverflow, Navigation } from 'swiper/modules';
import './slidersection.css';
import one from './media/sliderSectionImage/one.webp';
import two from './media/sliderSectionImage/second.png';
import third from './media/sliderSectionImage/third.webp';
import fourth from './media/sliderSectionImage/forth.webp';
import fifth from './media/sliderSectionImage/fifth.webp';
import circl from './media/sliderSectionImage/circle.png';

export default function SliderSection() {
    // Funzione per gestire l'apertura dei link ai PDF
    const handleDownload = (url) => {
        window.open(url, '_blank');
    };

    return (
        <div className="slider-wrrappper">
            <div className="slider-heading">
                <h2>ARGOMENTI e DOCUMENTAZIONE</h2>
            </div>
            <div className="section-slider">
                <Swiper
                    effect={'coverflow'}
                    grabCursor={true}
                    centeredSlides={true}
                    slidesPerView={'auto'}
                    coverflowEffect={{
                        rotate: 0,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        scale: 1,
                        slideShadows: false,
                    }}
                    navigation={true} // Utilizza le frecce di navigazione predefinite
                    modules={[EffectCoverflow, Navigation]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <div
                            className="card"
                            onClick={() => window.location.href = 'https://streamthegame.it/media/pdf/PMerchandise.pdf'}
                            style={{ cursor: 'pointer' }}
                        >
                            <img src={one} alt="Prima immagine" style={{ marginTop: '25px' }}/>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div
                            className="card"
                            onClick={() => window.location.href = 'https://streamthegame.it/media/pdf/PSocial.pdf'}
                            style={{ cursor: 'pointer' }}
                        >
                            <img src={two} alt="Seconda immagine" style={{ marginTop: '25px' }}/>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div
                            className="card"
                            onClick={() => window.location.href = 'https://streamthegame.it/media/pdf/PFumetto.pdf'}
                            style={{ cursor: 'pointer' }}
                        >
                            <img src={third} alt="Terza immagine" style={{ marginTop: '25px' }}/>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div
                            className="card"
                            onClick={() => window.location.href = 'https://streamthegame.it/media/pdf/PProgrammazione.pdf'}
                            style={{ cursor: 'pointer' }}
                        >
                            <img src={fourth} alt="Quarta immagine" style={{ marginTop: '25px' }} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div
                            className="card"
                            onClick={() => window.location.href = 'https://streamthegame.it/media/pdf/PModellazione.pdf'}
                            style={{ cursor: 'pointer' }}
                        >
                            <img src={fifth} alt="Quinta immagine" style={{ marginTop: '25px' }} />
                        </div>
                    </SwiperSlide>

                </Swiper>
                <div className="swiper-button-next">
                    <svg width="64px" height="64px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M8.29289 4.29289C8.68342 3.90237 9.31658 3.90237 9.70711 4.29289L16.7071 11.2929C17.0976 11.6834 17.0976 12.3166 16.7071 12.7071L9.70711 19.7071C9.31658 20.0976 8.68342 20.0976 8.29289 19.7071C7.90237 19.3166 7.90237 18.6834 8.29289 18.2929L14.5858 12L8.29289 5.70711C7.90237 5.31658 7.90237 4.68342 8.29289 4.29289Z" fill="#ffffff"></path> </g></svg>
                </div>
                <div className="swiper-button-prev">
                    <svg width="64px" height="64px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L9.41421 12L15.7071 18.2929C16.0976 18.6834 16.0976 19.3166 15.7071 19.7071C15.3166 20.0976 14.6834 20.0976 14.2929 19.7071L7.29289 12.7071C7.10536 12.5196 7 12.2652 7 12C7 11.7348 7.10536 11.4804 7.29289 11.2929L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289Z" fill="#ffffff"></path> </g></svg>
                </div>
            </div>
            <div className="circle">
                <img src={circl} alt="circle" />
            </div>
            <div className="circle2">
                <img src={circl} alt="circle" />
            </div>
        </div>
    );
}
