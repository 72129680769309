import "./index.css";
import overlay from "./media/Sfondo2.png";

export default function PdfSection() {

    const handleDownload = () => {
        const link = document.createElement("a");
        link.href = "https://www.streamthegame.it/media/pdf/FumettoCompleto.pdf";
        link.download = "Preview.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="main-section-container">
            <div className="pdf-overlay">
                <img src={overlay} alt="overlay" />
            </div>
            <div className="pdf-info">
                <span className="headings">
                    <h2>PROVA ORA LA DEMO</h2>
                    <h1>GRATUITAMENTE</h1>
                    <h3>E LASCIA UN COMMENTO</h3>
                </span>
                <span className="pdf-icons">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xlink="http://www.w3.org/1999/xlink"
                        width="177"
                        height="177"
                        viewBox="0 0 177 177"
                        onClick={() => window.location.href = 'https://drive.google.com/file/d/1qHtt_QGpsjUEphop67U_2yUz1uMspGpR/view'}
                        style={{ cursor: 'pointer' }}>
                        <defs>
                            <filter id="Ellipse_7" x="0" y="0" width="177" height="177" filterUnits="userSpaceOnUse">
                                <feOffset dy="6" input="SourceAlpha" />
                                <feGaussianBlur stdDeviation="4.5" result="blur" />
                                <feFlood flood-opacity="0.161" />
                                <feComposite operator="in" in2="blur" />
                                <feComposite in="SourceGraphic" />
                            </filter>
                        </defs>
                        <g id="Gamplay" transform="translate(13.5 7.5)">
                            <g transform="matrix(1, 0, 0, 1, -13.5, -7.5)" filter="url(#Ellipse_7)">
                                <g id="Ellipse_7-2" data-name="Ellipse 7" transform="translate(13.5 7.5)" fill="#fff" stroke="#707070" stroke-width="1">
                                    <circle cx="75" cy="75" r="75" stroke="none" />
                                    <circle cx="75" cy="75" r="74.5" fill="none" />
                                </g>
                            </g>
                            <path id="Path_18" data-name="Path 18" d="M34.746,52.851l-13.46,13.46A10.712,10.712,0,0,1,3,58.738V52.851L9.154,22.085A18.141,18.141,0,0,1,26.941,7.5H69.762A18.14,18.14,0,0,1,87.548,22.083L93.7,52.851v5.882a10.712,10.712,0,0,1-18.286,7.574L61.957,52.851Z" transform="translate(26.649 36.951)" fill="none" stroke="#4a2d5c" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" />
                            <path id="Path_19" data-name="Path 19" d="M13.5,7.5l4.535,9.07H36.176L40.711,7.5" transform="translate(47.895 36.951)" fill="none" stroke="#4a2d5c" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" />
                        </g>
                    </svg>

                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xlink="http://www.w3.org/1999/xlink"
                        width="177"
                        height="177"
                        viewBox="0 0 177 177"
                        onClick={() => window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSdH9PZHhDNgEtJ95IlIcNLZPQE8QejIiG2jAGSR4vYs6GirUw/viewform'}
                        style={{ cursor: 'pointer' }} >
                        <defs>
                            <filter id="Ellipse_8" x="0" y="0" width="177" height="177" filterUnits="userSpaceOnUse">
                                <feOffset dy="6" input="SourceAlpha" />
                                <feGaussianBlur stdDeviation="4.5" result="blur" />
                                <feFlood flood-opacity="0.161" />
                                <feComposite operator="in" in2="blur" />
                                <feComposite in="SourceGraphic" />
                            </filter>
                        </defs>
                        <g id="Feedback" transform="translate(13.5 7.5)">
                            <g transform="matrix(1, 0, 0, 1, -13.5, -7.5)" filter="url(#Ellipse_8)">
                                <g id="Ellipse_8-2" data-name="Ellipse 8" transform="translate(13.5 7.5)" fill="#fff" stroke="#707070" stroke-width="1">
                                    <circle cx="75" cy="75" r="75" stroke="none" />
                                    <circle cx="75" cy="75" r="74.5" fill="none" />
                                </g>
                            </g>
                            <path id="Freccia" d="M29.9,3.976H8.191a6.2,6.2,0,0,0-6.2,6.2V53.593a6.2,6.2,0,0,0,6.2,6.2H51.605a6.2,6.2,0,0,0,6.2-6.2V31.887" transform="translate(42.012 46.604)" fill="none" stroke="#4a2d5c" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" />
                            <path id="Freccia-2" data-name="Freccia" d="M40.512,3.8a6.579,6.579,0,0,1,9.3,9.3L20.357,42.561l-12.405,3.1,3.1-12.405Z" transform="translate(54.656 42.133)" fill="none" stroke="#4a2d5c" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" />
                        </g>
                    </svg>

                </span>
            </div>

            <div className="pdf-container">
                <iframe
                    src="https://www.streamthegame.it/media/pdf/Preview.pdf"
                    width="100%"
                    height="800px"
                    title="pdf"
                >
                    <p>Your browser does not support PDFs. <a href="https://www.streamthegame.it/media/pdf/Preview.pdf">Download the PDF</a> instead.</p>
                </iframe>
            </div>
            <button className="download-button" onClick={handleDownload}>
                Scarica Fumetto Completo
            </button>
        </div>
    );
}
