import "./footer.css";
import stars from "./media/stars.png";
import { FaInstagram, FaTiktok, FaFacebook, FaLinkedin } from 'react-icons/fa';

export default function Footer() {
    return (
        <div className="footerwrapper">
            <p>CONTATTAMI</p>
            <div className="social-icons">
                <a href="https://www.instagram.com/stream_thegame/" target="_blank" rel="noopener noreferrer">
                    <FaInstagram size={30} color="#ffffff" />
                </a>
                <a href="https://www.tiktok.com/@_._stream_._" target="_blank" rel="noopener noreferrer">
                    <FaTiktok size={30} color="#ffffff" />
                </a>
                <a href="https://www.facebook.com/profile.php?id=61563797212904" target="_blank" rel="noopener noreferrer">
                    <FaFacebook size={30} color="#ffffff" />
                </a>
                <a href="https://www.linkedin.com/in/sara-piergigli-150b252a7/" target="_blank" rel="noopener noreferrer">
                    <FaLinkedin size={30} color="#ffffff" />
                </a>
            </div>
            <div className="starsfoot">
                <img src={stars} alt="star" />
            </div>
        </div>
    );
}
