import "./aboutus.css"
import firstbar from "./media/firstbar.png"
import scndbar from "./media/scndbar.png"
import thrdbar from "./media/thirdbar.png"
import unreal from "./media/unreal.png"
import blender from "./media/blender (1).png"
import adobe from "./media/adobe (1).png"
import baji from "./media/anti.png"
import ecllips from  "./media/Path.png"
import stars from "./media/stars.png"
export default function AboutUs() {
    return (
        <div className="about-use-wrapper">
            <h1>SU DI ME</h1>
            <div className="container-about">
                <div className="image-container">
                    <img src={baji} alt="img" />
                    <span className="about-info">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="44" viewBox="0 0 40 44">
                                <path id="Home2" d="M6,18,24,4,42,18V40a4,4,0,0,1-4,4H10a4,4,0,0,1-4-4Z" transform="translate(-4 -2)" fill="none" stroke="#eee" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" />
                                <path id="Home1" d="M18,44V24H30V44" transform="translate(-4 -2)" fill="none" stroke="#eee" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" />
                            </svg>
                            <p>
                                Jesi (AN)
                            </p>
                        </span>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="43.936" height="44.019" viewBox="0 0 43.936 44.019">
                                <path id="Telefono" d="M44.158,33.962v6.021A4.014,4.014,0,0,1,39.783,44a39.72,39.72,0,0,1-17.322-6.162A39.153,39.153,0,0,1,10.417,25.793,39.733,39.733,0,0,1,4.255,8.39,4.016,4.016,0,0,1,8.25,4.014h6.021a4.016,4.016,0,0,1,4.015,3.453,25.735,25.735,0,0,0,1.405,5.64,4.015,4.015,0,0,1-.9,4.235l-2.549,2.549A32.116,32.116,0,0,0,28.282,31.935l2.549-2.55a4.015,4.015,0,0,1,4.235-.9,25.735,25.735,0,0,0,5.64,1.4A4.014,4.014,0,0,1,44.158,33.962Z" transform="translate(-2.23 -2.006)" fill="none" stroke="#eee" stroke-linecap="round" stroke-linejoin="round" stroke-width="4.014" />
                            </svg>
                            <p>
                                +39 3458016047
                            </p>
                        </span>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="45.571" height="36" viewBox="0 0 45.571 36">
                                <path id="Mail" d="M8,8H40a4.012,4.012,0,0,1,4,4V36a4.012,4.012,0,0,1-4,4H8a4.012,4.012,0,0,1-4-4V12A4.012,4.012,0,0,1,8,8Z" transform="translate(-1.215 -6)" fill="none" stroke="#eee" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" />
                                <path id="Mail-2" data-name="Mail" d="M44,12,24,26,4,12" transform="translate(-1.215 -6)" fill="none" stroke="#eee" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" />
                            </svg>
                            <p>
                                info@streamthegame.it
                            </p>
                        </span>
                    </span>
                </div>
                <div className="data-container">
                    <p>Ciao mi chiamo Sara, ho 22 anni,<br />
                        e in questo progetto ho voluto racchiudere le mie passioni e le mie abilità. </p>
                    <p>Attualmente lavoro come Graphic Designer ma sono aperta a collaborazioni e nuove sfide!</p>
                    <h1> LE MIE COMPETENZE</h1>
                    <div className="icons-about-software">
                        <span>
                            <img src={unreal } className="fst-img" alt="icons"></img>
                            <img src={firstbar } className="scnd-img" alt="icons"></img>

                        </span>
                        <span>
                            <img src={ blender} className="fst-img" alt="icons"></img>
                            <img src={ scndbar} className="scnd-img" alt="icons"></img>

                        </span>
                        <span>
                            <img src={adobe} className="fst-img" alt="icons"></img>
                            <img src={thrdbar} className="scnd-img" alt="icons"></img>

                        </span>
                    </div>
                </div>

            </div>
            <div className="ecllipse">
                <img src={ecllips} alt="path" />
            </div>
            <div className="stars">
                <img src={stars} alt="star" />
            </div>
        </div> 
    )
}